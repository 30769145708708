<template>
  <div class="company">
    <van-form>
      <!-- 
      <div class="group">
        <van-field
          v-model="form.company_key"
          name="关键字"
          label="关键字"
          placeholder="请输入行业关键字"
          :rules="[{ required: true, message: '请输入行业关键字' }]"
        />
        <van-field
          v-model="showAddr"
          is-link
          readonly
          label="所在区域"
          placeholder="请选择所在地区"
          @click="showArea = true"
        />
        <van-field
          v-model="form.company_address"
          label="详情地址"
          right-icon="location-o"
          placeholder="请输入地址或点击地图选择"
          @click-right-icon="selectAddr"
        />
      </div> -->

      <div class="group">
        <van-field
          v-model="form.store_person_name"
          name="联 系 人"
          label="联 系 人"
          placeholder="请输入联系人"
          :rules="[{ required: true, message: '请填写联系人' }]"
        />

        <van-panel title="商家介绍" desc="" status="">
          <van-field
            type="textarea"
            v-model="form.info"
            name="商家介绍"
            style="height: 70px;"
            placeholder="请输入商家介绍"
            :rules="[{ required: true, message: '请填写商家介绍' }]"
          />
        </van-panel>
        <!-- <van-field
          v-model="form.store_phone"
          name="联系电话"
          label="联系电话"
          placeholder="请输入联系电话"
          :rules="[{ required: true, message: '请填写联系电话' }]"
        /> -->
      </div>

      <div class="group">
        <van-row>
          <van-col span="12" style="text-align: center;">
            <van-uploader v-model="logoImg" :max-count="1" :after-read="uploadImg" />
            <div>商家LOGO(200*200)</div>
          </van-col>

          <van-col span="12" style="text-align: center;">
            <van-uploader v-model="wechatImg" multiple :max-count="1" :after-read="uploadImg" />
            <div>老板微信(200*200)</div>
          </van-col>
        </van-row>
      </div>

      <div class="group">
        <van-panel title="商家轮播图(750*300)" desc="" status="">
          <van-uploader v-model="slideshowImg" multiple :max-count="9" :after-read="uploadImg" />
        </van-panel>
      </div>

      <div class="group">
        <van-panel title="商家详情图(750*300)" desc="" status="">
          <van-uploader v-model="contentImg" multiple :max-count="9" :after-read="uploadImg" />
        </van-panel>
      </div>

      <div style="text-align: center; margin: 30px;">
        <van-button type="primary" style="padding: 0 50px;width: 100%;" @click="onSubmit">提交</van-button>
      </div>

      <van-popup v-model="showType" position="bottom">
        <van-picker
          show-toolbar
          value-key="name"
          :columns="typeList"
          @confirm="onSelectType"
          @cancel="showType = false"
        />
      </van-popup>
      <van-popup v-model="isShowTips" closeable close-icon="close" position="bottom">
        <div :style="companyKnowStyle" class="know-Container">商家须知</div>
      </van-popup>
      <van-popup v-model="showArea" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="citylist"
          @change="onChange"
          @close="show = false"
          @finish="onFinish"
        />
      </van-popup>
    </van-form>
    <!--选择地点-->
    <place-search @btnout="btnout" class="place-search" id="place-search" ref="homeSearch"></place-search>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PlaceSearch from '@/views/common/map/AMap'
import { getCompanyEidt, saveCompany } from '@/network/company'
import { getCityList } from 'network/nearby'
import { getType } from 'network/home'
import { uploadBase64 } from '@/network/publish'

export default {
  name: 'apply',
  components: {
    PlaceSearch
  },
  data() {
    return {
      typeList: [],
      showType: false,
      serviceType: '',
      showArea: false,
      isShowTips: false,
      companyKnowStyle: '',
      verifyStatus: false,
      verifyText: '获取验证码',
      showAddr: '',
      cascaderValue: '',
      citylist: [],
      logoImg: [],
      wechatImg: [],
      idImg: [],
      bizLicenseImg: [],
      slideshowImg: [],
      contentImg: [],
      form: {
        shouldKnow: false,
        company_name: '', //	商家名称
        sc_id: '',
        cat_id2: '', //	服务类型
        cat_id2Text: '',
        company_province: '', //	所在省
        company_city: '', //	所在市
        company_district: '', //	所在区
        company_key: '', //	服务关键字
        company_address: '', //	详细地址
        store_person_name: '', //	联系人
        // store_phone: '', //联系电话
        mobile: '',
        smsCode: '',
        store_logo: '', //	LOGO图片
        weixin_img: '', //	板微信图片
        business_licence_cert: '', //营业执照图片
        legal_identity_cert: '', //	身份证图片
        info: '', //商家介绍
        store_slide: [], //商家轮播图 （数组类型）
        store_infoimg: [], //商家详情图（数组类型）
        longitude: '', // 经度

        latitude: '' //纬度
      }
    }
  },
  computed: {
    ...mapState(['baseURL'])
  },
  created() {
    this.getType()
    this.getCityList(this.citylist)
    this.getCompanyEidt()
    const userInfo = JSON.parse(this.$store.state.userInfoJSON)
    if (userInfo) {
      this.form.mobile = userInfo.mobile
    }
    this.companyKnowStyle = 'width:' + (document.documentElement.clientWidth - 60) + 'px;'
    this.companyKnowStyle += 'height:' + (document.documentElement.clientHeight - 100) + 'px;'
  },
  methods: {
    getCompanyEidt() {
      var that = this
      getCompanyEidt().then((res) => {
        that.form = res.data.result.store
        that.logoImg[0] = {}
        that.logoImg[0].url = that.baseURL + that.form.store_logo

        that.wechatImg[0] = {}
        that.wechatImg[0].url = that.baseURL + that.form.weixin_img
        var i
        //轮播图
        for (i = 0; i < that.form.store_slide.length; i++) {
          that.slideshowImg[i] = {}
          that.slideshowImg[i].url = that.baseURL + that.form.store_slide[i]
        }
        //商家详情图

        for (i = 0; i < that.form.store_infoimg.length; i++) {
          that.contentImg[i] = {}
          that.contentImg[i].url = that.baseURL + that.form.store_infoimg[i]
        }
      })
    },
    btnout(item) {
      if (item) {
        this.form.company_address = item.name
        this.form.longitude = item.location.lng
        this.form.latitude = item.location.lat
      }
      let placesearch = document.getElementById('place-search')
      placesearch.style.left = 100 + 'vh'
      placesearch.style.height = 0 + 'px'
    },
    getType() {
      getType().then((res) => {
        this.typeList = res.data.result
      })
    },
    onSelectType(typeObj) {
      this.form.cat_id2Text = typeObj.name
      this.form.sc_id = typeObj.id
      this.form.cat_id2 = typeObj.id
      this.showType = false
    },
    showKnowTips() {
      this.isShowTips = true
    },
    getCityList(citylist, value) {
      getCityList(value).then((res) => {
        if (res.data.status == 1 && res.data.result) {
          res.data.result.data.forEach((item) => {
            item.value = item.id
            item.text = item.name
            if (item.level < 3) {
              item.children = []
            }
            citylist.push(item)
          })
        }
      })
    },
    onChange({ value, selectedOptions }) {
      if (selectedOptions.length < 3) {
        selectedOptions[selectedOptions.length - 1].children = []
        this.getCityList(selectedOptions[selectedOptions.length - 1].children, value)
      }
    },
    onFinish({ selectedOptions }) {
      this.showArea = false
      this.showAddr = selectedOptions.map((option) => option.text).join('/')

      this.form.company_province = selectedOptions[0].id
      this.form.company_city = selectedOptions[1].id
      this.form.company_district = selectedOptions[2].id
    },
    selectAddr() {
      let placesearch = document.getElementById('place-search')
      placesearch.style.left = 0 + 'vh'
      const h = document.documentElement.clientHeight
      placesearch.style.height = h + 'px'
    },
    getSmsCode() {
      this.verifyStatus = true
      this.countDown()
    },
    countDown() {
      var time = 60
      var timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer)
          this.verifyStatus = false
          this.verifyText = '获取验证码'
        } else {
          this.verifyStatus = true
          this.verifyText = `重新获取(${time}s)`
          time--
        }
      }, 1000)
    },
    uploadImg(file) {
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((file) => {
        uploadBase64(file.file.name, file.content).then((res) => {
          if (res.data && res.data.status === 1) {
            file.filePath = res.data.result[0].img
          }
        })
      })
    },
    onSubmit() {
      var that = this
      if (
        // !this.form.store_phone ||
        !this.form.store_person_name ||
        !this.form.company_key ||
        !this.form.info
      ) {
        return false
      }
      if (this.logoImg.length < 1) {
        this.$message('请上传LOGO图片')
        return false
      }
      if (this.slideshowImg.length < 1) {
        this.$message('请上传轮播图')
        return false
      }
      if (this.contentImg.length < 1) {
        this.$message('请上传商家详情图')
        return false
      }

      this.logoImg.forEach((file) => {
        this.form.store_logo = file.filePath
      })

      this.bizLicenseImg.forEach((file) => {
        this.form.business_licence_cert = file.filePath
      })

      this.idImg.forEach((file) => {
        this.form.legal_identity_cert = file.filePath
      })

      this.form.store_slide = []
      this.slideshowImg.forEach((file) => {
        var filePath = file.filePath ? file.filePath : file.url.replace(that.baseURL, '')
        this.form.store_slide.push(filePath)
      })

      this.form.store_infoimg = []
      this.contentImg.forEach((file) => {
        var filePath = file.filePath ? file.filePath : file.url.replace(that.baseURL, '')
        this.form.store_infoimg.push(filePath)
      })

      saveCompany(this.form).then((res) => {
        if (res.data.status == 1) {
          this.$router.push('/usernote')
        } else {
          this.$message(res.data.msg)
        }
      })
    }
  }
}
</script>

<style>
body {
  background-color: #efeff4;
}
.van-uploader__preview {
  margin: 8px;
}
.van-uploader__upload {
  margin: 8px;
}
</style>
<style scoped>
.company {
  margin-bottom: 100px;
}
.company .item {
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
}

.company .group {
  padding: 10px;
  margin-bottom: 8px;
  font-size: 10px;
  color: #999;
  background-color: #fff;
}
.place-search {
  position: fixed;
  width: 100vh;
  height: 1px;
  background-color: #fff;
  z-index: 10;
  left: 100vh;
  right: 0;
  bottom: 0;
  transition: left 0.6s;
}
.company-know {
  margin-top: 10px;
  font-size: 14px;
  padding: 10px 16px;
}
.company-know span {
  color: #ff0000;
}
.know-Container {
  margin: 50px 27px;
}
</style>
